import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';

import styles from "./CatalogBanner.module.css";

import { getCatalogPage, getGenreCatalogPage, getGenres } from "../../api/Api";
import { CatalogBook } from "../BookTypes/CatalogBook/CatalogBook";
import { Loader } from "../Loader/Loader";
import { SmallDropdown } from "../SmallDropdown/SmallDropdown";
import { SmallDropdownItem } from "../SmallDropdown/SmallDropdownItem";
import { TBRbook } from "../BookTypes/TBRBook/TBRBook";

export const CatalogBanner = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    let options = [
        // Options for cover size drop down
        <SmallDropdownItem key="cover" displayValue="Cover" onClick = { () => changeBookSize("cover")} />,
        <SmallDropdownItem key="exp" displayValue="Expanded" onClick = { () => changeBookSize("exp")} />,
    ]
    let sizeFromLocalStroageDisplayOptions = {
        // Options for converting local storage value to display value
        null : "Expanded",
        "exp" : "Expanded",
        "cover" : "Cover",
    }
    let colomnNumbersPerBookSize = {
        // Options for converting local storage value to content styling 
        null : styles.contentEXP,
        "exp" : styles.contentEXP,
        "cover" : styles.contentCOVER,
    }

    const [bookItems, setBookItems] = useState([]);
    const [bookObjects, setBookObjects] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams({ "page": "1"}); // saves page to url
    const page = searchParams.get("page"); // page value

    

    const [showLastPageMessage, setShowLastPageMessage] = useState(false);

    const [bookSize, setBookSize] = useState(localStorage.getItem("catalogBookSize"));

    const [genres, setGenres] = useState([]);
    const [selectedGenre, setSelectedGenre] = useState(null);
    const [genreObjects, setGenreObjects] = useState([]);

    useEffect(() => {
        // gets book page on first render and when page changes
        getBookPage(selectedGenre);
    },[page])

    useEffect(() => {
        // makes new book objects when bookSize changes
        if (bookItems.length > 0) {
            makeBookObjects(bookItems);
        }
    },[bookSize]);

    useEffect(() =>{
        // runs on first render, gets genres from back
        getGenresFromBack();
    },[]);

    useEffect(() => {
        // runs when genres come back from the back and when selected genre changes
        if (genres.length > 0) {
            makeGenreObjects();
        }
    },[genres, selectedGenre])

    // ----------------------------------------------------- PRE-RENDER


    async function getBookPage(genre) {
        // gets a page of books from the back
        setBookObjects(<div className={styles.loader}><Loader /></div>);
        let pageSize = 12;

        if (genre === null) {
            // pulls from all catalog books

            await getCatalogPage(page, pageSize, token)
                .then((foundBooks) => {
                    if (foundBooks.length === 0) {
                        // reached end of database
                        setShowLastPageMessage(true);
                        setBookObjects(null);
                    } else {
                        setShowLastPageMessage(false);
                        makeBookObjects(foundBooks);
                        setBookItems(foundBooks);
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch catalog page");
                    console.log(error);
                })
        } else {
            // pulls from catalog to match selected genre

            await getGenreCatalogPage(page, pageSize, genre.genreID, token)
                .then((foundBooks) => {
                    if (foundBooks.length === 0) {
                        // reached end of database
                        setShowLastPageMessage(true);
                        setBookObjects(null);
                    } else {
                        setShowLastPageMessage(false);
                        makeBookObjects(foundBooks);
                        setBookItems(foundBooks);
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch catalog genre page");
                    console.log(error);
                })
        }
    }

    async function getGenresFromBack() {
        // gets genre objects from back

        await getGenres(token)
            .then((foundGenres) =>{
                setGenres(foundGenres);
            })
            .catch((error) =>{
                console.log("failed to get genres from back");
                console.log(error);
            })
        
    }

    function incPage() {
        // increases page by one as caused by next button on bottom of page
        let newPage = Number(page) + 1;
        if (showLastPageMessage) {
            return;
        }
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

        window.scrollTo(0, 0); // scrolls window to top
    }

    function decPage() {
        // decreases page by one as caused by back button on bottom of page
        let newPage = Number(page) - 1;
        if (newPage < 1) {
            return;
        }
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

        window.scrollTo(0,0)
    }

    function makeBookObjects(books) {
        // makes book objects

        let tempList = [];
        if (bookSize === null || bookSize === "exp") {
            tempList = books.map((book) => 
                <CatalogBook bookItem = {book} key={book.bookID}/>
            )
        } else if (bookSize === "cover") {
            tempList = books.map((book) => 
                <TBRbook bookItem={book} key={book.bookID}/>
            )
        } 
        setBookObjects(tempList)
    }

    function makeGenreObjects() {
        // makes genre objects

        let temp = [];
        for (let i = 0; i < genres.length; i++) {
            temp.push(  <button className={selectedGenre?.genreID === genres[i].genreID ? styles.activeGenre : styles.inactiveGenre} 
                                key={i} 
                                onClick={ () => handleGenreClick(i)}> 
                                {genres[i].genreNAME}
                        </button>)
        }
        setGenreObjects(temp);
    }

    function changeBookSize(newSize) {
        // changes book size

        localStorage.setItem("catalogBookSize", newSize);
        setBookSize(newSize);
    }

    function handleGenreClick(newGenre) {
        // handles a change to selected genre

        // resets page to 1
        setSearchParams(prev => {
            prev.set("page", 1)
            return prev
        }, { replace: true });

        if (genres[newGenre].genreID === selectedGenre?.genreID) {
            // unselectes a genre if the currently selected genre is clicked again
            setSelectedGenre(null);
            getBookPage(null);
        } else {
            setSelectedGenre(genres[newGenre]);
            getBookPage(genres[newGenre]);
        }

       
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.header}>
                <div> Catalog </div>
                <div className={styles.pageSelector}>
                    {Number(page) !== 1 ?
                        <button onClick={decPage} className={styles.carrotBackButton}><img className={styles.carrot} src="/assets/images/backIcon.svg" alt="dec page button"/></button>
                        :
                        null
                    }
                    {!showLastPageMessage ?
                        <button onClick={incPage} className={styles.carrotNextButton}><img className={styles.carrot} src="/assets/images/nextIcon.svg" alt="inc page button"/></button>
                        :
                        null
                    }

                </div>
            </div>
            <div className={styles.sizeSelector}>
                <SmallDropdown buttonText={`Book Size: ${sizeFromLocalStroageDisplayOptions[bookSize]}`} content={options}/>
            </div>
            <div className={styles.genreContainer}>
                {genreObjects}
            </div>
            <div className={colomnNumbersPerBookSize[bookSize]} >
                { showLastPageMessage ? 
                    <div className={styles.emptyShelf}>You've reached the end of the shelf</div>
                    :
                    bookObjects
                }
            </div>

        </section>
    )
}